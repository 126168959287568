import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { CategoryDetailQuery } from '../../graphql-types';

import Layout from '../components/Layout';
import Checklists from '../components/Checklists';
import TagList from '../components/TagList';
import { getDescription } from '../utils/category';
import Icon from '../components/Icon';
import LinkLightAsAnchor from '../components/links/LinkLight/LinkLightAsAnchor';
import Categories from '../components/Categories/Categories';
import SEO from '../containers/SEO';

import './o-checklists-heading.scss';
import './o-suggest-a-checklist.scss';
import './o-category-detail-section.scss';
import '../containers/TagList/o-page-taglist.scss';
import './o-checklist-categories-section.scss';

interface CategoryTemplateContext {
  category: string;
}

interface Props {
  data: CategoryDetailQuery;
  pageContext: CategoryTemplateContext;
}

const CategoryTemplate: FC<Props> = ({ data, pageContext }) => {
  const { checklists, categories } = data;

  const title = pageContext.category;
  const description = getDescription(title);
  const checklistCount = checklists.totalCount;

  const sortedTags = checklists.tags.sort((t1, t2) => t2.totalCount - t1.totalCount);

  return (
    <Layout>
      <SEO title={title} description={description} />
      <div className="o-category-detail-section">
        <div className="o-checklists-heading">
          <h2 className="u-margin-bottom-0">
            {title} ({checklistCount})
          </h2>
          <LinkLightAsAnchor
            className="o-suggest-a-checklist"
            href="https://github.com/checkops/checklists/new/master/checklists?filename=checklists/change-this-filename.md&value=---%0Atitle%3A%20Title%20of%20Your%20Checklist%0Acategory%3A%20Change%20this%20value%20with%20one%20of%20them%3B%20%22Design%22%2C%20%22Front-End%22%2C%20%22Back-End%22%2C%20%22Mobile%22%2C%20%22DevOps%20%22General%22%0Adate%3A%20%22YYYY-MM-DD%22%0Atags%3A%20[%27update%27%2C%20%27with%27%2C%20%27your%27%2C%20%27own%27%2C%20%27good-tags%27]%0Adescription%3A%20Please%20give%20a%20description%20to%20your%20checklist%0A---%0A%0A-%20[%20]%20Provide%20a%20to-do%20%20%0APlease%20add%20a%20description%20to%20this%20to-do%20if%20necessary%0A%0A-%20[%20]%20Second%20step"
            target="_blank"
            rel="noopener noreferrer"
          >
            Suggest a Checklist{' '}
            <Icon className="o-suggest-a-checklist__icon" name="external-link" ariaHidden />
          </LinkLightAsAnchor>
        </div>
        <div className="row u-margin-top-xsmall">
          <div className="col col--md-6 col--xl-5 u-text-style-large-body">{description}</div>
        </div>
        <TagList
          className="o-page-taglist u-margin-top u-margin-bottom-medium@md-up u-margin-bottom@sm-down"
          tags={sortedTags.map(t => t.tag || '')}
        />
        <Checklists items={checklists.nodes} />
      </div>
      <hr className="u-margin-ends-0 u-color-primary-900" />
      <section className="o-checklist-categories-section">
        <h2 className="u-margin-bottom-medium">Checklist Categories</h2>
        <Categories categories={categories} current={title} />
      </section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query CategoryDetail($category: String!) {
    checklists: allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      totalCount
      nodes {
        ...Checklist
      }

      tags: group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
    }

    categories: allMarkdownRemark(limit: 1000) {
      ...CategoryList
    }
  }
`;

export default CategoryTemplate;
